import {
  applyMiddleware,
  createStore as createReduxStore,
  Middleware,
} from 'redux'
import { rootReducer } from './reducers'

const middleware: Middleware[] = []

let enhancer = applyMiddleware

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { composeWithDevTools } = require('redux-devtools-extension')
  enhancer = (...args: Middleware[]) =>
    composeWithDevTools(applyMiddleware(...args))
}

export const createStore = () =>
  createReduxStore(rootReducer(), enhancer(...middleware))
