import 'bootstrap/dist/css/bootstrap.min.css'
import './src/styles/global.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronUp,
  faCloudDownloadAlt,
  faEnvelope,
  faInbox,
  faLink,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment'
import 'moment/locale/it'
import { wrapWithProviver } from './wrap-with-provider'

library.add(
  faChevronUp,
  faCloudDownloadAlt,
  faEnvelope,
  faInbox,
  faLink,
  faMapMarkerAlt
)

Moment.globalLocale = 'it'

export const wrapRootElement = wrapWithProviver
