// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-board-tsx": () => import("./../src/templates/Board.tsx" /* webpackChunkName: "component---src-templates-board-tsx" */),
  "component---src-templates-group-tsx": () => import("./../src/templates/Group.tsx" /* webpackChunkName: "component---src-templates-group-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cookie-declaration-tsx": () => import("./../src/pages/cookie-declaration.tsx" /* webpackChunkName: "component---src-pages-cookie-declaration-tsx" */),
  "component---src-pages-utils-tsx": () => import("./../src/pages/utils.tsx" /* webpackChunkName: "component---src-pages-utils-tsx" */)
}

