import React from 'react'
import { Provider } from 'react-redux'
import { createStore } from './src/state/store'

export const wrapWithProviver = ({ element }) => {
  const store = createStore()

  // eslint-disable-next-line react/jsx-filename-extension
  return <Provider store={store}>{element}</Provider>
}
