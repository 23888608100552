import { createReducer } from '@reduxjs/toolkit'
import { combineReducers, AnyAction } from 'redux'
import {
  EmailSentPayload,
  showEmailSentErrorMsg,
  showEmailSentMsg,
} from './actions'
import { Reducer } from 'react'

interface Context {
  emailSent?: EmailSentPayload
}

const emailReducer = createReducer<Context>(
  {},
  {
    [showEmailSentMsg.type]: (state, action) => ({
      emailSent: { ...action.payload },
    }),
    [showEmailSentErrorMsg.type]: (state, action) => ({
      emailSent: { ...action.payload },
    }),
  }
)

export const rootReducer = () =>
  combineReducers({
    email: emailReducer,
  })

export type ReduxStorage = NonNullable<typeof rootReducer>

export type ReduxState = ReturnType<
  typeof rootReducer extends Reducer<infer S, AnyAction> ? S : never
>
