import { createAction } from '@reduxjs/toolkit'

export interface EmailSentPayload {
  message: string
  error: boolean
}

export const showEmailSentMsg = createAction<EmailSentPayload>('email/ok')
export const showEmailSentErrorMsg = createAction<EmailSentPayload>(
  'email/error'
)
